import { defineComponent, h } from 'vue';
import { useIntersectionObserver } from './intersectionObserver';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: undefined,
    },
    eager: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { intersectionRef, isIntersecting } = useIntersectionObserver();

    return () => h('img', {
      ref: intersectionRef,
      src: props.eager || isIntersecting.value ? props.src : undefined,
      alt: props.alt,
    });
  },
});
