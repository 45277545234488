import { defineComponent, h, ref, watch, onMounted, watchEffect } from 'vue';
import type { FileSource } from 'core/types';
import type { PropType } from 'vue';
import { debounce } from 'core/helpers';
import './image-slider.scss';
import Item from './ImageSliderItem';

// eslint-disable-next-line no-confusing-arrow
const easeInOutCubic = (t: number) => t < 0.5 ? 4 * t ** 3 : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

function scrollLeftWithAnimation(
  _container: HTMLElement,
  targetLocation: number,
): Promise<number> {
  const container = _container;
  const duration = 500;

  const startTime = performance.now();

  const startLocation = container.scrollLeft;
  if (targetLocation === startLocation) return Promise.resolve(targetLocation);

  // eslint-disable-next-line consistent-return
  return new Promise(resolve => requestAnimationFrame(function step(currentTime: number) {
    const timeElapsed = currentTime - startTime;
    const progress = Math.abs(duration ? Math.min(timeElapsed / duration, 1) : 1);

    container.scrollLeft = Math.floor(startLocation + (targetLocation - startLocation) * easeInOutCubic(progress));

    if (progress === 1 || container.clientWidth + container.scrollLeft === container.scrollWidth) {
      return resolve(targetLocation);
    }

    requestAnimationFrame(step);
  }));
}

const hasNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<FileSource[]>,
      required: true,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    alt: {
      type: String,
      default: undefined,
    },
    smallDots: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:activeIndex'],

  setup(props, { emit }) {
    const sliderRef = ref<HTMLElement>();

    const activeIndex = ref(props.activeIndex);

    const dotsSetting = ref({
      start: 0,
      end: 0,
      length: 0,
      hasMoreStart: false,
      hasMoreEnd: false,
      hasMore: false,
    });

    watchEffect(() => {
      const currentIndex = activeIndex.value;
      const itemsLength = props.items.length;
      const hasMore = itemsLength > 7 && inRange(currentIndex, 3, itemsLength - 4);
      const hasMoreStart = itemsLength > 5 && currentIndex >= 3;
      const hasMoreEnd = itemsLength > 5 && currentIndex <= itemsLength - 4;

      const cLength = hasMore ? 6 : 5;
      const length = itemsLength > cLength ? cLength : itemsLength;
      const end = Math.max(Math.min(currentIndex + 3, itemsLength), length);
      const start = Math.max(0, end - length);

      dotsSetting.value = {
        start,
        end,
        length,
        hasMoreStart,
        hasMoreEnd,
        hasMore,
      };
    });

    watch(() => props.activeIndex, (i) => {
      activeIndex.value = i;
      scrollToIndex(i, false);
    });

    watch(activeIndex, (i) => {
      emit('update:activeIndex', i);
    });

    onMounted(() => {
      if (activeIndex.value > 0) {
        scrollToIndex(activeIndex.value, false);
      }
    });

    function inRange(v: number, s: number, e: number) {
      return v >= s && v <= e;
    }

    function smoothScroll(el: HTMLElement, left: number) {
      if (hasNativeSmoothScroll) {
        return el.scrollTo({
          left,
          behavior: 'smooth',
        });
      }
      return scrollLeftWithAnimation(el, left);
    }

    function onScroll() {
      if (!sliderRef.value) return;

      const i = Math.round((sliderRef.value.scrollLeft / sliderRef.value.scrollWidth) * props.items.length);
      activeIndex.value = i;
    }

    function scrollToIndex(i: number, smooth = true) {
      if (!sliderRef.value) return;

      const scrollLeft = Math.floor(sliderRef.value.scrollWidth * (i / props.items.length));
      if (smooth) {
        smoothScroll(sliderRef.value, scrollLeft);
      } else {
        sliderRef.value.scrollLeft = scrollLeft;
      }
    }

    function genItems() {
      return h('div', {
        ref: sliderRef,
        class: 'image-slider__items',
        onScroll: debounce(onScroll, 100),
      }, props.items.map(({ path, thumbnail }, i) => genItem(props.smallDots ? thumbnail : path, i)));
    }

    function genItem(src: string, i: number) {
      return h('div', {
        class: {
          'image-slider__item': true,
          'image-slider__item--active': activeIndex.value === i,
        },
      }, h(Item, {
        src,
        class: 'image-slider__img',
        alt: props.alt,
        eager: i === 0,
      }));
    }

    function genDots() {
      if (props.items.length < 2) return undefined;
      return h('div', {
        class: {
          'image-slider__dots': true,
          'image-slider__dots--sm': props.smallDots,
        },
      }, Array(dotsSetting.value.length).fill(0)
        .map((_, i) => {
          const index = dotsSetting.value.start + i;
          return h('span', {
            class: {
              'image-slider__dot': true,
              'image-slider__dot--active': activeIndex.value === index,
              'image-slider__dot--more-start': dotsSetting.value.hasMoreStart,
              'image-slider__dot--more-end': dotsSetting.value.hasMoreEnd,
            },
            onClick: (e: Event) => {
              e.preventDefault();
              e.stopPropagation();

              scrollToIndex(index);
            },
          });
        }));
    }

    return () => h('div', {
      class: {
        'image-slider': true,
      },
    }, [
      genItems(),
      genDots(),
    ]);
  },
});
