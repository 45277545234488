import {
  defineComponent, h, ref, computed, watch, Teleport, withDirectives, onBeforeMount, nextTick,
} from 'vue';
import type { PropType } from 'vue';
import type { Nullable } from 'core/types';
import { awaitFrame } from 'core/helpers';
import { useRender } from 'composables/render';
import { ClickOutsideDirective } from 'directives/clickOutside';

import './autocomplete.scss';

type Input = Nullable<string | Date | Record<string, unknown>>

const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';

export const isChrome = Boolean(userAgent.match(/chrome/i));

function defaultFilter(value: Input, search: string | null) {
  return (
    value != null
    && search != null
    && typeof value !== 'boolean'
    && value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
  );
}

export default defineComponent({
  name: 'Autocomplete',

  props: {
    modelValue: {
      type: [String, Date, Object] as PropType<Input>,
      default: undefined,
    },
    search: {
      type: String,
      default: undefined,
    },
    disabled: Boolean,
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array as PropType<((v: Input) => string | true)[]>,
      default: undefined,
    },
    shouldValidate: {
      type: Boolean,
      default: true,
    },
    inputmode: {
      type: String,
      default: undefined,
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
    twoLine: {
      type: Boolean,
      default: false,
    },
    regular: {
      type: Boolean,
      default: false,
    },
    teleport: {
      type: String,
      default: 'body',
    },
    scrollBody: {
      type: String,
      default: '',
    },
    select: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'update:search', 'focus', 'blur'],

  setup(props, { attrs, slots, emit }) {
    const search = ref('');
    const selectedItem = ref(props.modelValue);
    const isActive = ref(false);
    const isFocused = ref(false);
    const errorMessage = ref('');
    const elementRef = ref<HTMLElement>();
    const elementRect = ref<DOMRect>();
    const inputRef = ref<HTMLInputElement>();

    // https://www.algolia.com/doc/guides/building-search-ui/
    // ui-and-ux-patterns/disabling-default-browser-behavior/js
    // "off" doesn’t work on Chrome, set invalid value
    const autocomplete = computed(() => (isChrome ? 'nope' : 'off'));

    const dimensionStyles = computed(() => {
      if (elementRect.value) {
        const scrollBody = props.scrollBody ? document.querySelector(props.scrollBody) : document.body;
        const offsetTop = scrollBody ? (scrollBody as HTMLElement).scrollTop : 0;
        const bodyRect = scrollBody && scrollBody.getBoundingClientRect();
        const bodyTop = (bodyRect?.top || 0);
        const bodyLeft = (bodyRect?.left || 0);

        const top = `${elementRect.value.top - bodyTop + offsetTop + 58 + 8}px`;
        const left = `${elementRect.value.left - bodyLeft}px`;
        const width = `${elementRect.value.width}px`;

        return {
          top,
          left,
          width,
          minWidth: width,
        };
      }
      return undefined;
    });

    const isDirty = computed(() => search.value != null
      && search.value !== '');

    const isSearching = computed(() => isDirty.value && search.value !== getText(selectedItem.value));

    const computedItem = computed(() => {
      if (isSearching.value && !props.noFilter) {
        return props.items.filter((item) => defaultFilter(getText(item), search.value));
      }
      return props.items;
    });

    watch(() => props.modelValue, (val) => {
      selectedItem.value = val;
    });

    watch(selectedItem, async (val) => {
      if (val === null || val === undefined) {
        return;
      }

      if (props.shouldValidate) {
        validate(getValue(val));
      }

      if (typeof val === 'object') {
        await selectItem(val);
      }
    });

    watch(search, (val) => {
      if (!isSearching.value) return;
      emit('update:search', val);
    });

    watch(isActive, (val) => {
      if (!val) {
        search.value = getText(selectedItem.value);
      }
      updateClientRect();
    });

    watch(isFocused, (val) => {
      if (val && !isActive.value) {
        open();
      }
    });

    watch(() => props.items, () => {
      nextTick(() => {
        updateClientRect();
      });
    });

    onBeforeMount(() => {
      if (props.modelValue) {
        search.value = getText(props.modelValue) || '';
      }
    });

    function open() {
      isActive.value = true;
    }

    function close() {
      isActive.value = false;
      isFocused.value = false;
    }

    function updateClientRect() {
      if (!elementRef.value) return;

      elementRect.value = elementRef.value.getBoundingClientRect();
    }

    function onClickOutside({ target }: MouseEvent) {
      if (elementRef.value === target || elementRef.value?.contains(target as HTMLElement)) {
        return;
      }
      close();
    }

    function getValue(item: any) {
      if (!item) return item;

      return item[props.itemValue] || item;
    }

    function getText(item: any) {
      if (!item) return item;

      return item[props.itemText] || item;
    }

    async function selectItem(item: any) {
      if (props.disabled) return;

      search.value = getText(item);
      selectedItem.value = item;
      emit('update:modelValue', item);

      await awaitFrame();
      close();
    }

    function onFocus(e: Event) {
      isFocused.value = true;
      emit('focus', e);
    }

    function onBlur(e: Event) {
      isFocused.value = false;
      emit('blur', e);
    }

    function focus() {
      inputRef.value?.focus();
    }

    function onInput(e: Event) {
      const target = e.target as HTMLInputElement;

      search.value = target.value;

      if (!isActive.value) open();
    }

    function validate(value: Input) {
      if (!props.rules || props.rules.length === 0) return false;
      const val = value || getValue(selectedItem.value);
      let message = '';
      for (let index = 0; index < props.rules.length; index++) {
        const rule = props.rules[index];
        const result = rule(val);

        if (typeof result === 'string') {
          message = result;
          break;
        }
      }
      errorMessage.value = message || '';
      return message === '';
    }

    function genDiv() {
      return h('div', {
        id: attrs.id,
        ref: inputRef,
        value: search.value,
        placeholder: props.placeholder,
        inputmode: props.inputmode,
        type: 'text',
        class: 'autocomplete__input',
        autocomplete: autocomplete.value,
      }, [search.value]);
    }

    function genInput() {
      return h('input', {
        id: attrs.id,
        ref: inputRef,
        value: search.value,
        placeholder: props.placeholder,
        inputmode: props.inputmode,
        type: 'text',
        class: 'autocomplete__input',
        autocomplete: autocomplete.value,
        onFocus,
        onBlur,
        onInput,
      });
    }

    function genLabel() {
      if (!props.label) return undefined;

      return h('label', {
        class: 'autocomplete__label',
      }, props.label);
    }

    function genItems() {
      if (!isActive.value || !computedItem.value.length) return undefined;

      const styles = props.regular
        ? {
          ...dimensionStyles.value,
          left: 0,
          right: 0,
          width: '100%',
          borderRadius: 0,
          boxShadow: 'none',
          maxHeight: 'unset',
        }
        : dimensionStyles.value;

      const content = h('div', {
        class: {
          autocomplete__items: true,
          'autocomplete__items_two-line': props.twoLine,
        },
        style: {
          ...styles,
        },
        onClick: (event: Event) => {
          event.stopPropagation();
        },
      }, computedItem.value.map((item) => h('div', {
        class: {
          autocomplete__item: true,
          autocomplete__item_active: selectedItem.value && getValue(selectedItem.value) === getValue(item),
        },
        onClick: () => selectItem(item),
      }, slots.item ? slots.item({ item }) : getText(item))));

      return h(Teleport, {
        to: props.teleport,
        disabled: !props.teleport,
      }, content);
    }

    function genError() {
      if (!errorMessage.value) return undefined;

      return h('div', {
        class: 'autocomplete__error',
      }, errorMessage.value);
    }

    useRender(() => [
      withDirectives(
        h('div', {
          ref: elementRef,
          class: {
            autocomplete: true,
            autocomplete_pointer: props.select,
            autocomplete_focused: isFocused.value,
            autocomplete_disabled: props.disabled,
            'autocomplete_has-label': props.label,
            'autocomplete_has-error': !!errorMessage.value,
            'autocomplete_not-empty': isDirty.value,
            autocomplete_regular: props.regular,
          },
        }, [
          withDirectives(h('div', {
            class: 'autocomplete__control',
            onClick: onFocus,
          }, [
            props.select ? genDiv() : genInput(),
            genLabel(),
          ]), [[ClickOutsideDirective, onBlur]]),
          genError(),
        ]),
        [
          [ClickOutsideDirective, onClickOutside],
        ],
      ),
      genItems(),
    ]);

    return {
      focus,
      validate,
    };
  },
});
