import { ModeratedActions } from 'modules/notModerated/types';
import { useNotModerated } from 'modules/notModerated';
import { useApiErrors } from 'composables/apiErrors';
import { IUser } from 'api/users/models/user';
import { Nullable } from 'core/types';
import { Ref } from 'vue';
import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersMutation } from 'store/users';

export function useFavorites() {
  const store = useStore();
  const { showError } = useApiErrors();
  const { checkModerate } = useNotModerated();

  function isFavoriteUser(user: Nullable<IUser>) {
    return !!user?.is_favorite;
  }

  const toggleFavorite = async (user: Ref<Nullable<IUser>>) => {
    if (!user.value) return;

    const isFavorite = user.value.is_favorite;
    if (!checkModerate(isFavorite ? ModeratedActions.Unlike : ModeratedActions.Like)) return;

    // eslint-disable-next-line no-param-reassign
    user.value.is_favorite = !isFavorite;

    let promise: Promise<AxiosResponse<{ favorites: IUser[] }>>;
    try {
      if (isFavorite) {
        promise = Api.userService.removeUserFromFavorites(user.value.id);
      } else {
        promise = Api.userService.addUserToFavorites(user.value.id);
      }
      await promise;
    } catch (e) {
      // eslint-disable-next-line no-param-reassign
      user.value.is_favorite = isFavorite;
      showError(e);
    } finally {
      store.commit(`${USER_STORE_KEY}/${UsersMutation.UpdateUsersFavorite}`, {
        userId: user.value.id,
        isFavorite: user.value.is_favorite,
      });
    }
  };

  return {
    isFavoriteUser,
    toggleFavorite,
  };
}
