import { defineComponent } from 'vue';
import Prompt from 'components/Prompt';
import { useRouter } from 'vue-router';
import { PaymentType } from 'core/types';

const InsufficientPrompt = defineComponent({
  components: {
    Prompt,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue'],

  setup() {
    const router = useRouter();
    function toWallet() {
      router.push({
        name: 'profile-wallet-refill',
        params: { type: PaymentType.Card },
      });
    }

    return {
      toWallet,
    };
  },
});

export default InsufficientPrompt;
