<template>
  <prompt
    :model-value="modelValue"
    :width="293"
    :title="$t('components.insufficientPrompt.title')"
    ok-text-path="components.insufficientPrompt.submit"
    @ok="toWallet"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script lang="ts" src="./InsufficientPrompt.ts" />
