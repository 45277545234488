<template>
  <div class="catalog-user">
    <is-majority-wrapper>
      <div
        class="catalog-user-avatar"
        :class="{ [`catalog-user-avatar--${avatarOutline}`]: !!avatarOutline }"
        @click="isViewerOpened = true"
      >
        <image-slider
          v-if="user.photos && user.photos.length > 0"
          v-model:active-index="activeImageIndex"
          :items="user.photos"
          :alt="$t('components.avatar.alt', { n: user.name })"
          :small-dots="true"
        />

        <div
          v-else
          class="catalog-user-avatar__icon"
        >
          <icon
            class="catalog-user-avatar__icon-img"
            :type="IconType.Big"
            :name="user.gender === Sex.Male ? IconName.Male : IconName.Female"
          />

          <txt
            class="catalog-user-avatar__icon-sub"
            :weight="TxtWeight.Bold"
          >
            {{ $t('components.avatar.none') }}
          </txt>
        </div>
      </div>
    </is-majority-wrapper>

    <div
      class="catalog-user-info"

      @click="handleShowUser"
    >
      <div class="catalog-user-header">
        <div class="catalog-user-header__title">
          <txt
            class="catalog-user-header__title-txt"
            :type="TxtType.Heading4"
          >
            {{ user.name }}
          </txt>
          <premium-icon
            :paid-params="user.paid_params"
            class="ml-1"
          />
          <div
            :class="{
              'catalog-user-status': true,
              'catalog-user-status_online': user.is_online,
            }"
          />
        </div>

        <icon-btn
          class="catalog-user-header__favorite"
          :size="Size.S"
          :color="favoriteColor"

          @click="handleFavorite"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.Heart"
          />
        </icon-btn>
      </div>

      <div class="catalog-user-subheader">
        <txt
          :type="TxtType.SmallText"
          :weight="TxtWeight.Medium"
          :leading="Size.L"
        >
          {{ `${user.age} ${$tc('user.age', user.age)}` }}
        </txt>

        <div
          v-if="locationRequired && locationCoords && user.geolocation"
          class="catalog-user-location"
        >
          <icon
            class="catalog-user-location__icon"
            :type="IconType.Fill"
            :name="IconName.Pin"
            :size="Size.Xs"
          />
          <txt
            :type="TxtType.SmallText"
            :weight="TxtWeight.Medium"
            :leading="Size.L"
          >
            {{ calcDistance(user.geolocation) }}
          </txt>
        </div>
      </div>

      <div
        v-if="user.typical_photo"
        class="flex"
      >
        <div
          :class="['catalog-user-label', `catalog-user-label_typical-photo`]"
        >
          <txt>
            {{ $t(`user.typicalPhoto`) }}
          </txt>
        </div>
      </div>

      <div
        v-if="user.tag"
        class="flex"
      >
        <div
          :class="['catalog-user-label', `catalog-user-label_${user.tag}`]"
        >
          <txt>
            {{ $t(`user.tag.${user.tag}`) }}
          </txt>
        </div>
      </div>

      <div
        class="catalog-user-messages"
        @click.prevent.stop="handleOpenChat"
      >
        <icon
          class="catalog-user-messages__icon"
          :type="IconType.Fill"
          :name="IconName.Message"
          :size="Size.M"
        />

        <txt
          :type="TxtType.SmallText"
          :weight="TxtWeight.Medium"
        >
          {{ $t('pages.catalog.user.write') }}
        </txt>

        <txt
          v-if="unreadMessagesCount > 0"
          :type="TxtType.SmallText"
          :weight="TxtWeight.Medium"
          class="ml-1"
        >
          (+{{ unreadMessagesCount }})
        </txt>
      </div>
    </div>
  </div>

  <photo-viewer
    v-if="user.photos && user.photos.length > 0"
    v-model:active-index="activeImageIndex"
    :show="isViewerOpened"
    :sources="user.photos"

    @close="isViewerOpened = false"
  />
</template>

<script lang="ts" src="./User.ts"></script>
<style lang="scss" src="./user.scss"></style>
